// Entry point for the build script in your package.json
// import "@hotwired/turbo-rails"
import "./controllers"
import Rails from "@rails/ujs"
// import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "./channels"

// import "../stylesheets/application"

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)


Rails.start()
// Turbolinks.start()
ActiveStorage.start()
import * as bootstrap from "bootstrap"
